import cookie from 'js-cookie';
import firebase from './firebase';
import logger from './log';
import { AUTH_TOKEN_COOKIE } from './constants';
import { followUser } from './db/users';
import * as Sentry from '@sentry/nextjs';
import analytics from './analytics';
import firebaseAdmin from './firebaseAdmin'; // Import your Firebase Admin SDK instance
import { NextApiRequest } from 'next';

class Auth {
  private _currentUser: firebase.User | null;
  private _currentDbUser: DG.UserModel | null;  // TODO
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
  private confirmationResult: firebase.auth.ConfirmationResult | null;
  private unsubscribeAuthStateChange: CallableFunction | null;
  private unsubscribeDbUser: CallableFunction | null;
  private authStateCallbacks: CallableFunction[];
  private dbUserCallbacks: ((user: DG.UserModel | null, fbUser: firebase.User | null) => void)[];
  private hasReceivedDBUser: boolean;

  constructor() {
    this._currentUser = null;
    this._currentDbUser = null;
    this.unsubscribeDbUser = null;
    this.recaptchaVerifier = null;
    this.confirmationResult = null;
    this.unsubscribeAuthStateChange = null;
    this.authStateCallbacks = [];
    this.dbUserCallbacks = [];
    this.hasReceivedDBUser = false;

    this.subscribeToAuthState();
  }

  public get currentUser() {
    return this._currentUser
  }

  public get currentDbUser() {
    return this._currentDbUser;
  }

  public logout() {
    return firebase.auth().signOut();
  }

  public async phoneNumberSignIn(phoneNumber: string): Promise<boolean> {
    if (!this.recaptchaVerifier) {
      logger.error('Recaptcha must be mounted before login');
      return false;
    }

    try {
      this.confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier);
      return true;
    } catch(err) {
      logger.error(err);
    }

    return false;
  }

  public confirmSecurityCode(code: string): Promise<firebase.auth.UserCredential> {
    if (!this.confirmationResult) {
      logger.error('Cannot confirm code before sign in');
      return Promise.reject();
    }

    return this.confirmationResult.confirm(code);
  }

  public setupRecaptcha(elementId: string) {
    this.clearRecaptcha();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(elementId, { size: 'invisible' });
  }

  public clearRecaptcha() {
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
      this.recaptchaVerifier = null;
    }
  }

  public onAuthStateChanged(callback: (user: firebase.User | null) => void) {
    this.authStateCallbacks.push(callback);

    return () => {
      const cbIndex = this.authStateCallbacks.indexOf(callback);
      if (cbIndex !== -1) {
        this.authStateCallbacks.splice(cbIndex, 1);
      }
    }
  }

  public onDbUserChanged(callback: (user: DG.UserModel | null, fbUser: firebase.User | null) => void) {
    this.dbUserCallbacks.push(callback);
    if (this.hasReceivedDBUser) {
      callback(this.currentDbUser, this.currentUser);
    }
    
    return () => {
      const cbIndex = this.dbUserCallbacks.indexOf(callback);
      if (cbIndex !== -1) {
        this.dbUserCallbacks.splice(cbIndex, 1);
      }
    };
  }

  private triggerAuthCallbacks (user: firebase.User | null) {
    this.authStateCallbacks.forEach((cb) => cb(user));

    Sentry.setUser(user ? {
      phoneNumber: user.phoneNumber,
      id: user.uid,
    } : null);
  }

  private subscribeToAuthState() {
    this.unsubscribeAuthStateChange = firebase.auth().onAuthStateChanged((user) => {
      logger.log(`Logged ${user ? 'in' : 'out'} ${user?.uid || ''}`);
      if (!user) {
        analytics.identify('');
        this.updateDBUser(null);
        this._currentUser = null;
      } else {
        this._currentUser = user;
        analytics.identify(user.uid, {
          phoneNumber: user.phoneNumber,
        });
        if (this.unsubscribeDbUser) {
          this.unsubscribeDbUser();
        }
        this.unsubscribeDbUser = followUser(this._currentUser.uid, (user) => this.updateDBUser(user));
      }
      this.setToken(user);
      this.triggerAuthCallbacks(user);
    });
  }

  private updateDBUser(val: DG.UserModel | null) {
    this.hasReceivedDBUser = true;
    if (val === null || val !== this._currentDbUser) {
      this._currentDbUser = val;
      if (val && val.bio) {
        analytics.identify(val.uid,{
          name: val.bio.name,
          username: val.username,
          isAdmin: val.isAdmin,
        });
      }
      this.dbUserCallbacks.forEach(cb => cb(this._currentDbUser, this._currentUser));
    }
  }

  public disconnectAuthState() {
    if (this.unsubscribeAuthStateChange) {
      this.unsubscribeAuthStateChange();
    }
  }

  private async setToken(user: firebase.User | null) {
    if (!user) {
      cookie.remove(AUTH_TOKEN_COOKIE);
      return;
    }

    const token  = await user.getIdToken();
    const expiresDate = new Date(Date.now() + 45 * 60 * 1000); // 45 minutes from now
    cookie.set(AUTH_TOKEN_COOKIE, token, { expires: expiresDate });
  } 

  public async sendPhoneVerification(phoneNumber: string): Promise<string> {
    if (!this.recaptchaVerifier) {
      throw new Error('Recaptcha must be mounted before sending verification');
    }

    try {
      const confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier);
      return confirmationResult.verificationId;
    } catch (error) {
      console.error('Error sending phone verification:', error);
      throw error;
    }
  }

  public async updatePhoneNumber(verificationId: string, securityCode: string): Promise<void> {
    const currentUser = firebase.auth().currentUser;
    
    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, securityCode);
      await currentUser.updatePhoneNumber(credential);
    } catch (error) {
      console.error('Error updating phone number:', error);
      throw error;
    }
  }

 

}

export default new Auth();